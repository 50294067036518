import * as React from 'react'
import * as styles from '../../../assets/styles.module.scss'
import axios from 'axios'

import {
    Col,
    Row,
    Form,
    Button
} from 'react-bootstrap'

interface Props {
    campaign: string
}

interface State {
    name?: String
    email?: String
    successMessage?: boolean
    errorMessage?: boolean
    emptyMessage?: boolean
    wrongEmail?: boolean
}

export default class LeadCaptureForm extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            name: '',
            email: '',
            successMessage: false,
            errorMessage: false,
            emptyMessage: false,
            wrongEmail: false
        }

        this._handleSubmit = this._handleSubmit.bind(this)
    }

    _handleSubmit(e: React.ChangeEvent<HTMLFormElement>) {
        e.preventDefault()

        if (!this.validateForm()) {
            this.setState({emptyMessage: true})
            return
        }

        const url = 'https://api.juvasoft.com/api/juvasoft/lead-capture'
        const { name, email } = this.state
        const { campaign } = this.props
        const params = { name, email, campaign }

        axios.post(url, params).then((result: any) => {
            window.location.href = 'https://storage.juvasoft.com/juvasoft-product-design-process-guide.pdf'
        }).catch((error: any) => {
            console.error(error)
            this.setState({errorMessage: true})
        })
    }

    validateForm() {
        if (this.state.name === undefined) { return false }
        if (this.state.email === undefined) { return false }

        return true
    }

    onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            email: event.target.value,
            wrongEmail: false
        })

        if (!this.validateEmail(event.target.value)) {
            this.setState({wrongEmail: true})
        }
    }

    validateEmail(element: string) {
        // tslint:disable-next-line:max-line-length
        var emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    
        if (emailRegex.test(element)) { return true }
    
        return false
    }

    renderSuccessMessage() {
        return (
            <div className={styles.ContactSuccess}>
                Thank you! We'll get in touch soon.
            </div>
        )
    }

    renderErrorMessage() {
        return (
            <div className={styles.ContactError}>
                We had a problem sending the message, do youi want to try it again later?
            </div>
        )
    }

    renderEmptyMessage() {
        return (
            <div className={styles.ContactEmpty}>
                Don't forget to fill out the fields.
            </div>
        )
    }

    renderWrongEmailMessage() {
        return (
            <div className={styles.ContactEmpty}>
                Oh no! We need a valid email from you
            </div>
        )
    }

    public render() {
        const renderSuccessMessage = (this.state.successMessage && this.renderSuccessMessage())
        const renderErrorMessage = (this.state.errorMessage && this.renderErrorMessage())
        const renderEmptyMessage = (this.state.emptyMessage && this.renderEmptyMessage())
        const renderWrongEmailMessage = (this.state.wrongEmail && this.renderWrongEmailMessage())

        return (
            <>
                <form name="contact" method="post" onSubmit={ this._handleSubmit }>
                    <input type="hidden" name="bot-field" value="juvasoft.com" />
                    <Row>
                        <Col xs={ 12 } md={ 12 }>
                            <Form.Group>
                                <Form.Label>Full Name</Form.Label>
                                <input
                                    className="form-control"
                                    type="text"
                                    ref="name"
                                    onChange={event => this.setState({name: event.target.value})}
                                    placeholder="Arya Stark" />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Work Email</Form.Label>
                                <input
                                    className="form-control"
                                    type="email"
                                    ref="email"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onChangeEmail(event)}
                                    placeholder="arya@starkindustries.com" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <Button className={styles.Download} type="submit">Download White Paper</Button>
                        </Col>
                    </Row>
                </form>
                {renderSuccessMessage}
                {renderErrorMessage}
                {renderEmptyMessage}
                {renderWrongEmailMessage}
            </>
        )
    }
}